<template>
  <div class="home">
    <p>
      会員機能ホーム画面
    </p>
  </div>
</template>

<script>
export default {
  name: 'Home',

  components: {
  }
}
</script>
